@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.root {
  background-color: $white100Color;
  width: 100%;
  margin-top: 30px;
  height: max-content;
  position: relative;
}

.table {
  height: 400px;
  max-height: 400px;
  width: 100%;
  overflow: hidden;
  border-radius: $p08;
  background: $white100Color;
  & *::-webkit-scrollbar-track {
    background: $white100Color;
  }
}
.summaryChartRoot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
