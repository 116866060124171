@import "@root/theme/colors.scss";

.checkboxContainer {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 1rem 2rem 0 2rem;
  box-sizing: border-box;

  * {
    flex-grow: 0 !important;
  }
}