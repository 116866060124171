@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.root {
  display: flex;
  flex-direction: column;
}

.body {
  max-width: 100%;
  flex: 1;
}

.headerContainer,
.footerContainer {
  padding-right: 40px;
  align-items: center;
}
.row {
  &:hover {
    background: $primary10Color;
  }
}
.footerPadding {
  padding-left: 2.5rem;
}
