@import "@root/theme/colors.scss";

.root {
  width: 100%;
  padding: 16px 24px;
  box-sizing: border-box;
  background: $pageBg;
  min-width: inherit;
  min-height: inherit;
  max-width: calc(100vw - 1em);
}

.header {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  margin-bottom: 24px;
  padding: 0 10px;
}

.controlHeader {
  padding-left: 20px;
}

.body {
  margin-right: -10px;
}

.tableBody {
  height: calc(var(--global-total-height, 100vh) - 260px);
}

.imageCell {
  width: 80px;
  height: 45px;
  border-radius: 4px;
  border: 1px solid #21212133;
  background: #eaeaea;
}

@media (max-width: 1600px) {
  .root {
    width: 100%;
    min-width: 1400px;
  }
}

.wrappper {
  position: relative;
  height: 100vh;
  overflow: hidden;
}