@import "@root/theme/colors.scss";

.defaultText {
  margin: 9px 0;
}

.imageCell {
  width: 180px;
  height: 100px;
}

.link {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: wrap;
  max-width: 250px;
  margin: 9px 0;
}

.adsContainer {
  width: 100%;
  flex-wrap: wrap;

  & > div {
    border: 1px solid $primaryDarkColor;
    flex-grow: 0;
    flex-shrink: 1;
    padding: 0 8px;
    border-radius: 8px;
    word-break: break-all;
  }
}
