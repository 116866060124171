.root {
    width: 100%;
    height: 446px;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
  }
  
  .legendList {
    display: flex !important;
    row-gap: 0.75rem;
  }
  
  .BgStripesContainer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 285px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
  
    .item {
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
  
  .tableScrollContainer {
    position: relative;
    z-index: 2;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  
  .tableContainer {
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: fit-content;
    overflow-y: hidden;
    height: 100%;
  }
  
  .mainInfoContainer {
    position: relative;
    width: 100%;
    height: 450px;
  }
  