@import "@root/theme/colors.scss";
@import "@root/theme/utils.scss";

.chartItem {
  .chartContainer {
    height: 285px;
    padding: 0;
    margin: 0;
  }
}

.bars {
  aspect-ratio: auto !important;
}
