@import "@root/theme/colors.scss";
@import "@root/theme/utils.scss";

.productsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 42px;
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.hoveredImage {
  .labelContainer {
    display: block !important;
  }

  & > img {
    border-color: $primary80Color !important;
  }
}

.imageContainer {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 64px;
  height: 48px;
  padding-top: 8px;
  cursor: pointer;

  &:nth-last-child(-n + 2) {
    &:not(:nth-child(-n + 1)) {
      .labelContainer {
        right: 0;
        left: unset;
      }
    }
  }

  &:hover {
    .closeIcon {
      visibility: visible;
    }

    & > img {
      border-color: $primary80Color;
    }

    .labelContainer {
      display: block;
    }
  }

  & > img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;

    &:active {
      border-color: $primary140Color;
    }
  }

  .closeIcon {
    position: absolute;
    visibility: hidden;
    right: -10px;
    top: -2px;
    background-color: $secondary100Color;
    border-radius: 64px;
    font-weight: 700;
    padding: 3px;
  }

  .labelContainer {
    display: none;
    position: absolute;
    max-height: 45px;
    top: 60%;
    left: 70%;
    padding: 8px;
    background-color: $assetImagesBg;
    z-index: 10;
    line-clamp: 2;
    white-space: nowrap;
    box-shadow: $primaryShadowL2;
    border-radius: 4px;
  }
}
