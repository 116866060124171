@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.root {
  width: 100%;
  height: max-content;
  position: relative;
}

.goalsGrid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: $p12;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: $p12;
}

.gridGroups {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $p12;
}
.gridGroups .fullWidth {
  grid-column: 1 / -1;
}

.grid .fullWidth {
  grid-column: 1 / -1;
}

.table {
  height: calc(100vh - 400px);
  max-height: 400px;
  width: 100%;
  overflow: hidden;
  border-radius: $p08;
  background: $white100Color;
  & *::-webkit-scrollbar-track {
    background: $white100Color;
  }
}

.filterMenusWrapper {
  position: relative;
}
.filterMenu,
.metricsMenu,
.sortByMenu,
.groupByMenu {
  border: 1px solid $primaryDarkColor;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 4px 15px 0 rgba(32, 32, 32, 0.1);
  background-color: $white100Color;
  z-index: 2;
}
.filterMenu {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.filterMenuSelects {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.metricsMenu {
  position: absolute;
  min-width: auto;
  top: 50px;
}
.groupByMenu {
  position: absolute;
  min-width: auto;
  top: 50px;
}
.select {
  min-width: 200px;
}
.filterSelect {
  margin-left: 10px;
  min-width: 200px;
}
.selectInput {
  margin-bottom: 10px;
}
.filteredBy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  padding: 12px 12px 0;
  margin: 12px 0 0;
  border-radius: 8px;
  border: 1px solid $primary20Color;
  background: $primary05Color;
}
.winnersTitle {
  margin: 32px 0 16px;
}
.topAds {
  margin: 30px 0;
}
.topAdsList {
  display: grid;
  grid-template-columns: repeat(4, minmax(255px, 350px));
  gap: $p12;
  justify-content: start;
  margin: 20px 0;
}
.topAdsControlPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.filterButton {
  position: relative;
}
.metricsSelect {
  border: 1px solid $primaryDarkColor;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 4px 15px 0 rgba(32, 32, 32, 0.1);
  background-color: $white100Color;
  z-index: 2;

  position: absolute;
  min-width: auto;
  top: 40px;
  right: 0
}
.select {
  min-width: 200px;
}
