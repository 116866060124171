@import "@root/theme/colors.scss";

.modalBase {
    padding: 25px;
    width: 370px;
}
.modalVideoPreview {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 10;
    min-height: auto;
    background: $white100Color;
    border-radius: 15px;
    border: 1px solid $utilBorder;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 310px;
    &.modalLeftPosition {
        left: 60px;
        z-index: 1000;
    }
}
.modalVideoPreviewIframeWrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    min-height: 535px;
}
.videoLoader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $secondaryDarkShadow;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.videoIframe {
    min-height: 535px;
    max-width: 310px;
}
.video {
    min-height: 535px;
    height: 100%;
    max-width: 310px;
}
