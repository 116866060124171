@import "@root/theme/colors.scss"
@import "@root/theme/size.scss"

.root
  //display: flex
  //flex-direction: column
  //gap: $p08
  background: $white100Color
  padding: 0
  border: 1px solid transparent
  cursor: pointer
  overflow: hidden

.mediaContainer
  position: relative
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  border-radius: $p08
  background-color: $black20Color
  position: relative
  height: 100%

  .views
    display: flex
    align-items: center
    position: absolute
    bottom: 3%
    right: 3%
    gap: $p08
    padding: $p04 $p08
    border-radius: $p04
    background: $black60Color
    i
      font-size: 1.25em

  .image
    width: auto
    height: 100%

  .video
    height: 100%
    width: auto
    min-width: 330px

  .videoWrap
    position: relative
    height: 100%

  .videoLoader
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: $secondaryDarkShadow
    z-index: 2
    display: flex
    align-items: center
    justify-content: center


.trendContainer
  display: flex
  flex-direction: column
  width: 100%
  flex: 1 0 250px
  padding: 10px
  box-sizing: border-box
  position: relative

.trendLoader
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: $secondaryDarkShadow
  z-index: 2
  display: flex
  align-items: center
  justify-content: center

.chart
  max-width: 100%
  flex: 1 1 100%
